import { ErrorResponse } from '@/types/ErrorResponse';
import axiosInstance from '@/utils/axiosConfig'; // Importer l'instance Axios configurée
import axios from 'axios';
const API_URL = '/inventaire';

const DetailService = {


    async getAll({ id, page, pageSize, sortBy, sortOrder, search }: { id: number, page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`,
                search: search
            });
            const response = await axiosInstance.get(`inventaire/detail-inventaire/${id}/? ${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching inventaire.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async extractAll({ id, page, pageSize, sortBy, sortOrder, search }: { id: number, page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }): Promise<Blob | ErrorResponse> {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`,
                search: search,
                export: 'excel'
            });
            const response = await axiosInstance.get(`inventaire/detail-inventaire/${id}/?${params.toString()}`, {
                responseType: 'blob'
            });
            
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching inventaire.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
};

export default DetailService;
