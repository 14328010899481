import axiosInstance from '@/utils/axiosConfig'; // Importer l'instance Axios configurée
import { Inventaire, InventaireWithDepartement, InventaireWithLocation, InventaireWithZone } from "@/models/Inventaire";
import axios from 'axios';

const API_URL = '/inventaire';

interface ErrorResponse {
    status: number;
    message: string;
}

const InventaireService = {
    async create(inventaire: Inventaire): Promise<number | ErrorResponse> {
        try {
            const response = await axiosInstance.post(`${API_URL}/create_inventaire/`, inventaire);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while creating the inventaire.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async addInventoryDepartement(inventaire: InventaireWithDepartement): Promise<number | ErrorResponse> {
        try {
            const response = await axiosInstance.post(`${API_URL}/create_inventaire_departement/`, inventaire);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while adding the department inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
    async addInventoryZone(inventaire: InventaireWithZone): Promise<number | ErrorResponse> {
        try {
            const response = await axiosInstance.post(`${API_URL}/create_inventaire_zone/`, inventaire);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while adding the zone inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
    async addInventoryLocation(inventaire: InventaireWithLocation): Promise<number | ErrorResponse> {
        try {
            const response = await axiosInstance.post(`${API_URL}/create_inventaire_location/`, inventaire);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while adding the location inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
    async getAll(page: number, pageSize: number, sortBy: string, sortOrder: string, search: string) {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`, 
                search: search,
            });
            const response = await axiosInstance.get(`${API_URL}/all_inventaire/?${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching the inventaires.'
                };
            } 
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },

    async getAllInventaireZone(page: number, pageSize: number, sortBy: string, sortOrder: string, search: string) {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`, 
                search: search,
            });
            const response = await axiosInstance.get(`${API_URL}/all_inventaire_zone/?${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching the inventaire zones.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },

    async getAllInventaireLocation(page: number, pageSize: number, sortBy: string, sortOrder: string, search: string) {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`, 
                search: search,
            });
            const response = await axiosInstance.get(`${API_URL}/all_inventaire_location/?${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching the inventaire locations.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },

    async getAllInventaireDepartements(page: number, pageSize: number, sortBy: string, sortOrder: string, search: string) {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`, 
                search: search,
            });
            const response = await axiosInstance.get(`${API_URL}/all_inventaire_departement/?${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching the inventaire departements.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },

    async getById(id: number): Promise<Inventaire | ErrorResponse> {
        try {
            const response = await axiosInstance.get(`${API_URL}/edit_inventaire/${id}/`);
            return response.data as Inventaire;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching the inventaire.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },

    async update(id: number, inventaire: Inventaire): Promise<number | ErrorResponse> {
        try {
            const response = await axiosInstance.put(`${API_URL}/update_inventaire/${id}/`, inventaire);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while updating the inventaire.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getByIdInventoryDepartement(id: number): Promise<InventaireWithDepartement | ErrorResponse> {
        try {
            const response = await axiosInstance.get(`${API_URL}/edit_inventaire_departement/${id}/`);
            return response.data as InventaireWithDepartement;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while retrieving the department inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
    async updateInventoryDepartement({id,inventaire}: {id: number, inventaire: InventaireWithDepartement} ): Promise<number | ErrorResponse> {
        try {
            const response = await axiosInstance.put(`${API_URL}/update_inventaire_departement/${id}/`, inventaire);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while updating the department inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
    async getByIdInventoryZone(id: number): Promise<InventaireWithZone | ErrorResponse> {
        try {
            const response = await axiosInstance.get(`${API_URL}/edit_inventaire_zone/${id}/`);
            return response.data as InventaireWithZone;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while retrieving the zone inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
    async updateInventoryZone({ id, inventaire }: { id: number; inventaire: InventaireWithZone; }): Promise<number | ErrorResponse> {
        try {            
            const response = await axiosInstance.put(`${API_URL}/update_inventaire_zone/${id}/`, inventaire);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while updating the zone inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
    async getByIdInventoryLocation(id: number): Promise<InventaireWithLocation | ErrorResponse> {
        try {
            const response = await axiosInstance.get(`${API_URL}/edit_inventaire_location/${id}/`);
            return response.data as InventaireWithLocation;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while retrieving the location inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
    async updateInventoryLocation({id,inventaire}: {id: number, inventaire: InventaireWithLocation}): Promise<number | ErrorResponse> {
        try {
            const response = await axiosInstance.put(`${API_URL}/update_inventaire_location/${id}/`, inventaire);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while updating the location inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getDetailInventaireParemplacements({id,page, pageSize, sortBy, sortOrder, search }:{id:number,page: number, pageSize: number, sortBy: string, sortOrder: string, search: string}){
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`, 
                search: search,
            }); 
            const response = await axiosInstance.get(`/detail/inventaire/${id}/?${params.toString()}`);
            return { data: response.data.data, totalRows: response.data.count, result:response.data.result };
        } catch (error) {
            
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while updating the location inventory.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async lancerEmplacement(list: number[]): Promise<number | ErrorResponse> {
        try {
            // Sending list of IDs with proper structure
            const response = await axiosInstance.post(`${API_URL}/update-start-at/`, { ids: list });
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while updating the emplacement.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getEmplacementCounts(inventaireId: number) {
        try {
            const response = await axiosInstance.get(`${API_URL}/emplacements-count/${inventaireId}/`);
            return response.data;
        } catch (error) {
            
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while updating the emplacement.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    }
};

export default InventaireService;
