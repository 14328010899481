<template>
  <v-app id="inspire">
    <v-layout >
      <v-navigation-drawer v-model="subMenu" :rail="rail" @click="rail = false" mobile-breakpoint='lg'>
        <v-list-item :prepend-avatar="require('@/assets/logo.png')" title="AMS" nav>
        </v-list-item>
        <v-divider></v-divider>
        <v-list density="compact" nav>
          <v-list-item link to="/">
            <template v-slot:prepend>
              <v-icon>mdi-view-dashboard</v-icon>
            </template>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/Articles-receptionner">
            <template v-slot:prepend>
              <v-icon>mdi-clipboard-list</v-icon>
            </template>
            <v-list-item-content>
              <v-list-item-title>Articles receptionnés</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/Articles">
            <template v-slot:prepend>
              <v-icon>mdi-table-chair</v-icon>
            </template>
            <v-list-item-content>
              <v-list-item-title>Articles affectés</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group prepend-icon="mdi-text-search" v-model="subMenu">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="Inventaire"></v-list-item>
            </template>
            <v-list-item link to="/Inventaire-Location">
              <v-list-item-content>
                <v-list-item-title>Par site</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/Inventaire-zone">
              <v-list-item-content>
                <v-list-item-title>Par zone</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/inventaire-departement">
              <v-list-item-content>
                <v-list-item-title>Par département</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/Inventaire-emplacement">
              <v-list-item-content>
                <v-list-item-title>Par emplacement</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item link to="/Article_consomme">
            <template v-slot:prepend>
              <v-icon>mdi-hammer-wrench</v-icon>
            </template>
            <v-list-item-content>
              <v-list-item-title>Articles consommés</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main class="d-flex align-center justify-center my-10" style="min-height: 300px;">
        <router-view></router-view>
      </v-main>
      <v-app-bar scroll-threshold="0" color="#FFC107">
        <template v-slot:prepend>
          <v-app-bar-nav-icon @click.stop="rail = !rail"></v-app-bar-nav-icon>
        </template>
          <v-menu
            open-on-hover
          >
            <template v-slot:activator="{ props }">
              <v-avatar  v-bind="props">
                <v-icon icon="mdi-account-circle"></v-icon>
              </v-avatar>
            </template>

            <v-list>
              <v-list-item @click="logout">
                <v-list-item-title >Deconnexion</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

      </v-app-bar>
      <v-footer app color="yellow-lighten-5 d-flex justify-content-center">
        <span class="white--text">&copy; 2024 SM@TCH</span>
      </v-footer>
    </v-layout>
  </v-app>
</template>

<script lang="ts">
import store from '@/store';
import { clearTokens } from '@/utils/cookieUtils';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  setup() {
    const hideSidebarText = ref(false);
    const subMenu = ref(true);
    const rail = ref(true);
    const router = useRouter();
    const logout= async()=>{
      try{
        const response =store.dispatch('AuthModule/logout');
        if(await response==200){
          router.push('/login')
        }else{
          clearTokens(); // Remove tokens from cookies or localStorage
          router.push('/login')
        }
        
      }
      catch (error) {
        console.error(error);
    }
      
    }
    return { hideSidebarText, subMenu, rail,logout };

  }
});
</script>

<style>
body {
  background-color: #F4F6F9 !important;
}

.v-app-bar {
  height: 48px;
}
</style>