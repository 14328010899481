import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MasterLayoutTow from '@/layout/MasterLayout.vue';
// import ArticleView from '@/views/ArticleView.vue';
// import DashboardView from '@/views/DashboardView.vue';
// import addArticle from '@/views/AddArticleView.vue';
// import UpdateArticle from '@/views/UpdateArticleView.vue';
// import LoginPage from '@/views/LoginView.vue';
// import InventorySiteView from '@/views/InventorySiteView.vue';
// import ArticlesReceptionnerView from '@/views/ArticlesReceptionnerView.vue';
// import InventoryZoneView from '@/views/InventoryZoneView.vue';
// import InventoryLocationView from '@/views/InventoryLocationView.vue';
// import InventoryDepartementView from '@/views/InventoryDepartementView.vue';
// import DetailInventory from '@/views/DetailInventaireView.vue';
// import Operations from '@/views/OperationsView.vue';
// import Tags from '@/views/TagHistoryView.vue';
// import Article_consomme from '@/views/ArticlesCosommeView.vue';

// import DetailInventaireParEmplacementView from '@/views/DetailInventaireParEmplacmentView.vue';

import { getTokens } from '@/utils/cookieUtils';
// Définition des routes
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: MasterLayoutTow,
    children: [
      { path: '', name: 'Dashboard', component: () => import('@/views/DashboardView.vue'), meta: { requiresAuth: true } },
      { path: 'Articles', name: 'Articles', component: () => import('@/views/ArticleView.vue'), meta: { requiresAuth: true } },
      { path: 'Articles-receptionner', name: 'ArticlesReceptionner', component: () => import('@/views/ArticlesReceptionnerView.vue'), meta: { requiresAuth: true } },
      { path: 'Ajouter-article', name: 'addArticle', component: () => import('@/views/AddArticleView.vue'), meta: { requiresAuth: true } },
      { path: 'Modifier-article/:id', name: 'updateArticle', component: () => import('@/views/UpdateArticleView.vue'), meta: { requiresAuth: true } },
      { path: 'Inventaire-emplacement', name: 'InventaireEmplacement', component: () => import('@/views/InventorySiteView.vue'), meta: { requiresAuth: true } },
      { path: 'Inventaire-zone', name: 'InventaireZone', component: () => import('@/views/InventoryZoneView.vue'), meta: { requiresAuth: true } },
      { path: 'Inventaire-Location', name: 'InventaireLocation', component: () => import('@/views/InventoryLocationView.vue'), meta: { requiresAuth: true } },
      { path: 'Inventaire-departement', name: 'InventaireDepartement', component: () => import('@/views/InventoryDepartementView.vue'), meta: { requiresAuth: true } },
      { path: 'Inventaire-detail/:id', name: 'InventaireDetail', component: () => import('@/views/DetailInventaireView.vue'), meta: { requiresAuth: true } },
      { path: 'Operations/:id', name: 'Operations', component: () => import('@/views/OperationsView.vue'), meta: { requiresAuth: true } },
      { path: 'Tags/historique/:id', name: 'Tags', component: () => import('@/views/TagHistoryView.vue'), meta: { requiresAuth: true } },
      { path: 'Article_consomme', name: 'Article_consomme', component: () => import('@/views/ArticlesCosommeView.vue'), meta: { requiresAuth: true } },
      { path: 'Detail/Inventaire/Emplacements/:id', name: 'DetailInventaireParEmplacement', component: () => import('@/views/DetailInventaireParEmplacmentView.vue'), meta: { requiresAuth: true } },
    ],
  },
  { path: '/login', name: 'Login', component: () => import('@/views/LoginView.vue') },
  { path: '/:catchAll(.*)', redirect: { name: 'Dashboard' } }, // Redirection des routes non trouvées
];

// Création du routeur
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
});

// Middleware d'authentification
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!getTokens();  
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login' });
  } else if (to.name === 'Login' && isAuthenticated) {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
});

export default router;