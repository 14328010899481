import { createStore } from 'vuex';
import ArticleModule from '@/store/modules/ArticlesModule';
import ProduitModule from './modules/ProduitModule';
import FournisseurModule from './modules/FournisseurModule';
import NatureModule from './modules/NatureModule';
import MarqueModule from './modules/MarqueModule';
import DepartementModule from './modules/DepartementModule';
import AuthModule from './modules/authModule';
import EmplacementModule from './modules/EmplacementModule';
import InventaireModule from './modules/InventorySiteModule';
import ZoneModule from './modules/ZoneModule';
import LocationModule from './modules/LocationModule';
import ItemModule from './modules/ItemsModule';
import DetailInventaireModule from '@/store/modules/DetailInventaireModule';
import OperationModule from '@/store/modules/OperationsModule';
import TagModule from '@/store/modules/TagHistoryModule';



const store = createStore({
  modules: {
    ArticleModule,
    ProduitModule,
    FournisseurModule,
    NatureModule,
    MarqueModule,
    DepartementModule,
    AuthModule,
    InventaireModule,
    EmplacementModule,
    ZoneModule,
    LocationModule,
    ItemModule,
    DetailInventaireModule,
    OperationModule,
    TagModule
  },
});

export default store;
